export const DUYURU_YETKILISI = 109;
export const FIRMA_ONAY_YETKILI = 110;
export const JEOFIZIK_MUHENDISI = 15;
export const JEOLOJI_MUHENDISI = 14;
export const KAMU_MUHENDISI = 12;
export const KURUM_KULLANICI_DUZENLEME_YETKILI = 112;
export const PROJE_DURUMU_DEGISTIRME = 118;
export const ADMIN = 10;
export const SUBE_MUDURU = 11;
export const PROJE_ALANI_KILITLEME_YETKILI = 111;
export const JEOLOJI_VE_JEOFIZIK_MUHENDISI = 119;
export const UYGULAMA_YONETICISI = 106;
export const BAKANLIK_DAIRE_BASKANI = 108;
export const CEVRE_VE_SEHIRCILIK_MUHENDISI = 19;
export const IL_MUHENDISI = 101;
export const IL_SUBE_MUDURU = 107;
export const FIRMA = 8;
