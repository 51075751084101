import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UisKeySkeleton from '@iconscout/react-unicons/icons/uil-key-skeleton';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Message from './Message';
import Notification from './Notification';
import Search from './Search';
import Settings from './settings';

import { Dropdown } from '../../dropdown/dropdown';
import Heading from '../../heading/heading';
import { Popover } from '../../popup/popup';
import { useAuth } from '../../../hooks/useAuth';
import Can from '../../can/can';

import { ADMIN, KAMU_MUHENDISI } from '../../../constants/roleConstant';

import { KURUM, FIRMA, MUHENDIS, ADMIN as ADMINTYPE } from '../../../constants/typeConstant';

const AuthInfo = React.memo(() => {
  const { user, logout } = useAuth();

  let profileUrl = '';
  switch (user.type) {
    case KURUM:
      profileUrl = `/admin/BilgiSayfalari/KurumBilgileri/KurumDetay/${user.organizationIdStr}`;
      break;
    case FIRMA:
      profileUrl = `/admin/BilgiSayfalari/FirmaBilgileri/FirmaDetay/${user.organizationIdStr}`;
      break;
    case MUHENDIS:
    case ADMINTYPE:
      profileUrl = `/admin/BilgiSayfalari/MuhendisBilgileri/MuhendisDetay/${user.muhendisIdHash}`;
      break;
    default:
      break;
  }

  const [state, setState] = useState({
    flag: 'tr',
  });
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const { flag } = state;

  const SignOut = (e) => {
    e.preventDefault();
    logout(() => navigate('/'));
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">
              {user.name} {user.surname}
            </Heading>
            <p>{user.userName}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={profileUrl}>
              <UilUser /> {t('info.profile')}
            </Link>
          </li>
          <li>
            <Link to="#">
              <UisKeySkeleton /> {t('info.changePassword')}
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <UilSignout /> {t('info.signOut')}
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = (value, e) => {
    e.preventDefault();
    setState({
      ...state,
      flag: value,
    });
    i18n.changeLanguage(value);
  };

  const country = (
    <NavAuth>
      <Link onClick={(e) => onFlagChangeHandle('tr', e)} to="#">
        <img src={require('../../../static/img/flag/tr.png')} alt="" />
        <span>Türkçe</span>
      </Link>
      <Link onClick={(e) => onFlagChangeHandle('en', e)} to="#">
        <img src={require('../../../static/img/flag/en.png')} alt="" />
        <span>English</span>
      </Link>
    </NavAuth>
  );

  return (
    <InfoWraper>
      {/* <Search />
      <Message />
      <Notification />
      <Settings /> */}
      {/* <Can perform={[KAMU_MUHENDISI]}>
        <div className="ninjadash-nav-actions__item ninjadash-nav-actions__language">
          <Dropdown placement="bottomRight" content={country} trigger="click">
            <Link to="#" className="ninjadash-nav-action-link">
              <img src={require(`../../../static/img/flag/${flag}.png`)} alt="" />
            </Link>
          </Dropdown>
        </div>
      </Can> */}
      <div className="ninjadash-nav-actions__item ninjadash-nav-actions__author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="ninjadash-nav-action-link">
            <Avatar
              style={{ backgroundColor: 'aliceblue' }}
              icon={<UilUser />}
              // src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"
            />
            <span className="ninjadash-nav-actions__author--name">
              {user.name} {user.surname}
            </span>
            <UilAngleDown />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
});

export default AuthInfo;
